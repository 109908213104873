import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { portfolio } from './data';
import { PortfolioWrapper, PortfolioDetails } from './styled';
import Detail from './Detail';
import ReactGA from 'react-ga';


	

const Portfolio = () => {
	ReactGA.initialize('UA-191423910-1');
	ReactGA.pageview('/home');
	const [detail, setDetail] = useState({});

	const seeDetail = (data) => {
		setDetail(data);
	};
	const detailOff = () => {
		setDetail({});
	};
	return (
		<PortfolioWrapper>
			{Object.keys(detail).length !== 0 && (
				<PortfolioDetails className={'details-wrapper'}>
					<Detail detailOff={detailOff} detail={detail} />
				</PortfolioDetails>
			)}
			{Object.keys(detail).length === 0 && (
				<>
					<Grid container>
						<Grid item md={12} xs={12}>
							<p className='text-center page-title'>Some of my works</p>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						{portfolio.map((port, index) => {
							console.log(port, 'port');
							return (
								<Grid item md={4} xs={12}>
									<div
										className='project-wrapper	'
										style={{ backgroundImage: `url(${port.data.images[0]})` }}
										onClick={() => seeDetail(port)}>
										{/* <img src={} alt='portfolio' /> */}
										<div className='overlay'>
											<div className='overlay-content'>
												<p>{port.name}</p>
											</div>
										</div>
									</div>
								</Grid>
							);
						})}
					</Grid>
				</>
			)}
		</PortfolioWrapper>
	);
};

export default Portfolio;
