import facility1 from '../../assets/images/facility/facility-1.png';
import facility2 from '../../assets/images/facility/facility-2.png';
import facility3 from '../../assets/images/facility/facility-3.png';
import facility4 from '../../assets/images/facility/facility-4.png';
import flood1 from '../../assets/images/flood/flood-1.png';
import flood2 from '../../assets/images/flood/flood-2.png';
import flood3 from '../../assets/images/flood/flood-3.png';
import flood4 from '../../assets/images/flood/flood-4.png';
import ay1 from '../../assets/images/ay/ay-1.png';
import ay2 from '../../assets/images/ay/ay-2.png';
import ay3 from '../../assets/images/ay/ay-3.png';
import ay4 from '../../assets/images/ay/ay-4.png';
import bj1 from '../../assets/images/bj/bj-1.png';
import bj2 from '../../assets/images/bj/bj-2.png';
import pm1 from '../../assets/images/pm/pm1.png';
import pm2 from '../../assets/images/pm/pm2.png';
import pm3 from '../../assets/images/pm/pm3.png';
import meed1 from '../../assets/images/meed/meed-1.png';
import meed2 from '../../assets/images/meed/meed-2.png';
import meed3 from '../../assets/images/meed/meed-3.png';
import meed4 from '../../assets/images/meed/meed-4.png';
import weather1 from '../../assets/images/weather/weather-1.png';
import weather2 from '../../assets/images/weather/weather-2.png';

export const images = {
	flood: [flood1, flood2, flood3, flood4],
	facility: [facility1, facility2, facility3, facility4],
	ay: [ay1, ay2, ay3, ay4],
	bj: [bj1, bj2],
	meed: [meed1, meed2, meed3, meed4],
	weather: [weather1, weather2],
	pm: [pm1, pm2, pm3],
};
