import { images } from './image';
export const portfolio = [
	{
		name: 'Packman',
		link: {
			available: true,
			url: 'http://www.packman.arnabshuvo.com',
		},
		data: {
			images: images.pm,
			details: {
				description: 'Application to find the weight and cost of a node package with Nodejs and ReactJs',
				stack: ['NodeJs', 'ReactJs', 'CLI', 'Styled Components', 'Material UI'],
			},
		},
	},
	{
		name: 'Facility Monitoring System',
		link: {
			available: false,
		},
		data: {
			images: images.facility,
			details: {
				description:
					'A facility monitoring system where satellite data are provided through API and represented in dashboard with analysis and with statistical representation.',
				stack: ['ReactJs', 'Reacthooks', 'Redux', 'Thunk', 'CesiumJs', 'Echart', 'Styled-Components', 'Material UI'],
			},
		},
	},
	{
		name: 'Flood Monitoring System',
		link: {
			available: false,
		},
		data: {
			images: images.flood,
			details: {
				description:
					'A flood monitoring system where satellite data are provided through API and represented in dashboard with analysis and with statistical representation to determine the damage level of floods in various areas.',
				stack: ['ReactJs', 'Reacthooks', 'Redux', 'Thunk', 'Mapbox', 'Echart', 'Styled-Components', 'Material UI'],
			},
		},
	},
	{
		name: 'Meed',
		link: {
			available: true,
			url: 'https://meedbankingclub.com/',
		},
		data: {
			images: images.meed,
			details: {
				description:
					'A cross platform banking system where clients from enlisted banks can use this service which includes various perks and opportunities for the users.',
				stack: ['ReactJs', 'GraphQL', 'Contentfull', 'GatsbyJs', 'Styled Components'],
			},
		},
	},
	{
		name: 'Alteryouth',
		link: {
			available: true,
			url: 'http://13.250.90.186:8080/',
		},
		data: {
			images: images.ay,
			details: {
				description:
					'An online system where users as contributors can provide scholarship to children who are about to discontinue their study before primary education This project is also sponsored by UNDP',
				stack: ['ReactJs', 'Reacthooks', 'Redux', 'Thunk', 'Botstrap', 'SCSS'],
			},
		},
	},
	{
		name: 'BlackJack',
		link: {
			available: true,
			url: 'https://blackjackcrack.arnabshuvo.com',
		},
		data: {
			images: images.bj,
			details: {
				description: 'BlackJackCrack is an online version of BlackJack Card Game built to RnD about project structure',
				stack: ['ReactJs', 'Redux', 'Thunk', 'Material UI', 'ReactHook', 'Styled-Components'],
			},
		},
	},
	{
		name: 'Weather Update',
		link: {
			available: true,
			url: 'https://weather.arnabshuvo.com',
		},
		data: {
			images: images.weather,
			details: {
				description:
					'A weather update app developed as an assignment project from AirAsia, Anyone can find current weather update of any desired areaa',
				stack: ['ReactJs', 'Redux', 'Thunk', 'Material UI', 'ReactHook', 'Styled-Components', 'Google Place API', 'OpenWeatherMap'],
			},
		},
	},
];
