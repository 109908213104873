import React from 'react';
import { TerminalWrapper, TerminalContainer, Typer } from './styled';
import Grid from '@material-ui/core/Grid';

const Terminal = ({ route }) => {
	return (
		<TerminalWrapper>
			<TerminalContainer container alignItems={'center'} justify={'center'}>
				<Grid item md={6}>
					<div className='terminal-box'>
						<div className='terminal-header'>Lets Start Coding</div>
						<div className='terminal-body'>
							<div>
								<div className='content'>
									<p>
										<span className='pc-name'>arnab@arnabshuvo.com</span>
										<span className='path-name'>~/</span>
									</p>
									{/* <span className='destination'>cd portfolio</span> */}
								</div>
								<div className='dest'>
									<Typer>cd {route}</Typer>
								</div>
								<div style={{ clear: 'both' }}></div>
							</div>
						</div>
					</div>
				</Grid>
			</TerminalContainer>
		</TerminalWrapper>
	);
};

export default Terminal;
