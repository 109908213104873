import React, { useState, useEffect } from "react";
import { LeftBarWrapper } from "./styled";
import Terminal from "../../../Components/Terminal";
import { useHistory, useParams } from "react-router-dom";
import home from "../../../assets/images/home.jpg";
import about from "../../../assets/images/about.jpg";
import portfolio from "../../../assets/images/portfolio.jpg";
import contact from "../../../assets/images/contact.jpg";

const LeftBar = () => {
  const [terminal, setTerminal] = useState(false);
  const [route, setRoute] = useState("");
  const [selected, setSelected] = useState("");
  const [tooltip, setTooltip] = useState("");
  const history = useHistory();
  const param = useParams();

  const showTerminal = (route) => {
    const path = route === "home" ? "/" : `/${route}`;
    if (history.location.pathname !== path) {
      setRoute(route);
      route = route === "home" ? "" : route;
      setTerminal(true);
      setSelected(path);
      if (window.innerWidth > 767) {
        setTimeout(() => {
          setTerminal(false);
          history.push(`/${route}`);
        }, 1500);
      } else {
        setTerminal(false);
        history.push(`/${route}`);
      }
    }
  };
  useEffect(() => {
    setSelected(history.location.pathname);
  }, [history.location.pathname, param]);

  const showToolTip = (tooltip) => {
    setTooltip(tooltip);
  };

  return (
    <LeftBarWrapper>
      {terminal && window.innerWidth > 767 && <Terminal route={route} />}
      <ul>
        <li
          className={selected === "/" ? "selected" : ""}
          onMouseLeave={() => showToolTip("")}
          onMouseEnter={() => showToolTip("home")}
          onClick={() => showTerminal("home")}
        >
          <i className="flaticon-home"></i>
          {tooltip === "home" && (
            <div className="tooltip-div">
              <p>Home</p>
              <img src={home} alt={"arnab-shuvo"} />
            </div>
          )}
        </li>
        <li
          className={selected === "/about-me" ? "selected" : ""}
          onMouseLeave={() => showToolTip("")}
          onMouseEnter={() => showToolTip("about")}
          onClick={() => showTerminal("about-me")}
        >
          <i className="flaticon-businessman-talking-about-data-analysis"></i>
          {tooltip === "about" && (
            <div className="tooltip-div">
              <p>About Me</p>
              <img src={about} alt={"arnab-shuvo"} />
            </div>
          )}
        </li>
        <li
          className={selected === "/portfolio" ? "selected" : ""}
          onMouseLeave={() => showToolTip("")}
          onMouseEnter={() => showToolTip("portfolio")}
          onClick={() => showTerminal("portfolio")}
        >
          <i className="flaticon-suitcase"></i>
          {tooltip === "portfolio" && (
            <div className="tooltip-div">
              <p>Portfolio</p>
              <img src={portfolio} alt={"arnab-shuvo"} />
            </div>
          )}
        </li>
        <li
          className={selected === "/contact" ? "selected" : ""}
          onMouseLeave={() => showToolTip("")}
          onMouseEnter={() => showToolTip("contact")}
          onClick={() => showTerminal("contact")}
        >
          <i className="flaticon-phone-book"></i>
          {tooltip === "contact" && (
            <div className="tooltip-div">
              <p>Contact</p>
              <img src={contact} alt={"arnab-shuvo"} />
            </div>
          )}
        </li>
      </ul>
    </LeftBarWrapper>
  );
};

export default LeftBar;
