import styled from 'styled-components';

export const LeftBarWrapper = styled.div`
	width: 100%;
	ul {
		margin: 0;
		padding: 0;
		text-align: center;
		li {
			padding: 20px 0;
			display: block;
			cursor: pointer;
			position: relative;
			i {
				&:before {
					font-size: 30px;
					color: #fff;
					margin-left: 0px;
				}
				&:hover {
					&:before {
						color: yellow;
					}
				}
			}
			&.selected {
				border-left: 3px solid #f8e900;
			}
			.tooltip-div {
				position: absolute;
				width: 150px;
				background: #101131;
				top: 24%;
				right: -258%;
				padding: 10px;
				box-sizing: border-box;
				border: 1px solid #f8e900;
				p {
					margin: 0;
					font-size: 15px;
					color: #f8e900;
					margin-bottom: 10px;
				}
				img {
					max-width: 100%;
				}
			}
		}
		@media screen and (max-width: 767px) {
			li {
				display: inline-block;
				box-sizing: border-box;
				width: 24%;
				&.selected {
					border-left: 0px solid #f8e900;
					i {
						&:before {
							font-size: 30px;
							color: yellow;
							margin-left: 0px;
						}
					}
				}
				.tooltip-div {
					display: none;
				}
			}
		}
	}
`;
