import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const AboutContainer = styled(Grid)`
	min-height: 100vh;
	.my-img {
		width: 100%;
	}
`;
export const AboutWrapper = styled(Grid)`
	padding: 30px;
	background: rgba(13, 14, 37);
	@media screen and (max-width: 767px) {
		padding: 0px;
	}
`;

export const Aboutme = styled.div`
	padding: 30px 30px;

	box-sizing: border-box;
	.about {
		&-title {
			margin-top: 0px;
			padding-bottom: 10px;
			border-bottom: 1px solid #fff;
			font-size: 30px;
			font-weight: bold;
		}
		&-description {
			margin: 0px;
			padding-bottom: 30px;
			text-align: justify;
			line-height: 25px;
			box-sizing: border-box;
			font-size: 18px;
			ul {
				margin: 0;
				li {
					list-style: circle;
					padding: 10px 0;
					.bolder {
						color: #f5d71b;
						font-weight: bold;
						font-size: 25px;
						text-transform: uppercase;
						@media screen and (max-width: 767px) {
							font-size: inherit;
						}
					}
				}
				@media screen and (max-width: 767px) {
					padding: 0;
					li {
						text-align: left;
					}
				}
			}
		}
	}
	.experience {
		padding-top: 30px;
		.timeline {
			width: 100%;
		}
		.timeline ul {
			list-style-type: none;
			border-left: 2px solid #fff;
			padding: 10px 5px;
		}
		.timeline ul li {
			padding: 0 15px 50px 15px;
			position: relative;
			cursor: pointer;
			transition: 0.5s;
		}
		.timeline ul li span {
			display: inline-block;
			padding: 0 5px;
			font-size: 15px;
			&.company-name {
				color: #f5d71b;
				font-size: 20px;
			}
		}
		.timeline ul li .content h3 {
			color: #f5d71b;
			font-size: 20px;
			padding-top: 5px;
		}
		.timeline ul li .content h5 {
			font-size: 25px;
			padding-top: 5px;
			margin: 0;
		}
		.timeline ul li .content h6 {
			span {
				background: #948734;
				margin-right: 10px;
				margin-bottom: 10px;
				padding: 0px 10px;
			}
		}
		.timeline ul li .content p {
			padding: 5px 0px 5px 0px;
			font-size: 20px;
			text-align: justify;
		}
		.timeline ul li:before {
			position: absolute;
			content: '';
			width: 10px;
			height: 10px;
			background-color: #f5d71b;
			border-radius: 50%;
			left: -11px;
			top: 4px;
			transition: 0.5s;
		}
		@media (max-width: 300px) {
			.timeline {
				width: 100%;
				padding: 30px 5px 30px 10px;
			}
			.timeline ul li .content h3 {
				color: #34ace0;
				font-size: 15px;
			}
		}
	}
`;

export const Skill = styled.p`
	padding-right: 10px;
	span {
		display: inline-block;
	}
	.skill-title {
		width: 100%;
		color: #fff;
	}
`;

export const SkillBar = styled.span`
	width: 100%;
	height: 10px;
	background: #cdcdcd;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: ${(props) => props.number}%;
		background: #f5d71b;
	}
`;
