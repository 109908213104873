import styled from 'styled-components';

export const DetailWrapper = styled.div`
	position: relative;
	padding: 60px;
	.close-btn {
		position: absolute;
		right: 60px;
		top: 60px;
		line-height: 8px;
		color: #fff;
		background: transparent;
		font-size: 20px;
		transition: all 0.5s ease;
		padding: 10px;
		z-index: 11111;
		border: 1px solid #f8e900;
		cursor: pointer;
		&:hover {
			background: #f8e900;
			color: #000;
		}
		@media screen and (max-width: 767px) {
			right: 30px;
			top: 30px;
		}
	}
	.each-slide {
		img {
			width: 100%;
		}
	}
	.project-ino-title {
		margin: 0px;
		font-size: 30px;
		color: #f8e900;
		border-bottom: 1px solid #3f4152;
	}
	.project-description {
		font-size: 20px;
		text-align: justify;
		span {
			font-size: 15px;
			display: inline-block;
			padding: 5px 10px;
			margin-right: 10px;
			margin-bottom: 10px;
			background: #f8e900;
			color: #000;
		}
	}
	.link_btn {
		background: #f8e900;
		border: 1px solid #f8e900;
		color: #000;
		margin-top: 20px;
		padding: 20px 30px;
		font-size: 20px;
		transition: all 0.5s ease;
		text-decoration: none;
		&:hover {
			background: transparent;
			color: #fff;
		}
	}

	@media screen and (max-width: 767px) {
		padding: 0px;
		padding-bottom: 120px;
		padding-top: 60px;
	}
`;
