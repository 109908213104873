import React from 'react';
import './App.css';
import Landing from './page';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
	return (
		<Router>
			<Landing />
		</Router>
	);
}

export default App;
