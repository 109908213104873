import React from 'react';
import Grid from '@material-ui/core/Grid';
import { DetailWrapper } from './styled';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const Detail = ({ detail, detailOff }) => {
	const slideImages = detail.data.images;
	return (
		<DetailWrapper>
			<button className='close-btn' onClick={detailOff}>
				X
			</button>
			<p className='page-title text-center'>{detail.name}</p>
			<Grid container justify={'center'}>
				<Grid container item md={12} xs={12} spacing={2}>
					<Grid item md={8} xs={12}>
						<div className='slide-container'>
							<Slide>
								{slideImages.map((image, index) => {
									return (
										<div className='each-slide'>
											<img src={image} alt={'arnab-portfolio'} />
										</div>
									);
								})}
							</Slide>
						</div>
					</Grid>
					<Grid item md={4} xs={12}>
						<p className='project-ino-title '>Description:</p>
						<p className='project-description '>{detail.data.details.description}</p>
						<p className='project-ino-title '>Stack:</p>
						<p className='project-description '>
							{detail.data.details.stack.map((stack, index) => {
								return <span>{stack}</span>;
							})}
						</p>
						{detail.link.available && (
							<a target='_blank' rel='noopener noreferrer' className='link_btn' href={detail.link.url}>
								Let see
							</a>
						)}
					</Grid>
				</Grid>
			</Grid>
		</DetailWrapper>
	);
};

export default Detail;
