import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const ContactWrapper = styled(Grid)`
	padding: 30px;
	background: rgba(13, 14, 37);
	min-height: 100vh;
	position: relative;
	@media screen and (max-width: 767px) {
		padding: 0px;
	}
	.contact-image {
		img {
			width: 100%;
		}
	}
	.contact-info {
		.intro {
			text-align: justify;
			line-height: 45px;
			font-size: 20px;
			margin: 0;
		}
		ul {
			padding: 0;
			@media screen and (max-width: 767px) {
				margin: 0;
			}
			li {
				list-style: none;
				padding: 10px 0px;
				@media screen and (max-width: 767px) {
					font-size: 15px;
				}
				a {
					color: #fff;
					display: flex;
					align-items: center;
					&:hover {
						color: yellow;
					}
					p {
						&:first-child {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
`;
