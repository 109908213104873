import React from 'react';
import { TaskbarWrapper } from './styled';
import Grid from '@material-ui/core/Grid';
import LeftBar from './LeftBar';

const Taskbar = () => {
	return (
		<TaskbarWrapper>
			<Grid style={{ height: '100%' }} container direction={window.innerWidth > '767px' ? 'column' : 'row'}>
				<Grid item md={12} xs={12}>
					<LeftBar />
				</Grid>
			</Grid>
		</TaskbarWrapper>
	);
};

export default Taskbar;
