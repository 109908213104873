import React from "react";
import { ContactWrapper } from "./styled";
import Grid from "@material-ui/core/Grid";
import myContact from "../../assets/images/my-contact.jpg";
import { LinkedIn, Facebook, Mail, Phone } from "@material-ui/icons";
import ReactGA from "react-ga";

const Contact = () => {
  ReactGA.initialize("UA-191423910-1");
  ReactGA.pageview("/home");
  return (
    <ContactWrapper>
      <Grid container justify="center" spacing={3}>
        <Grid item md={12} xs={12}>
          <p className="text-center page-title">Lets have some discussion</p>
        </Grid>
        <Grid item container md={8} xs={12} spacing={3}>
          <Grid item md={4} xs={12}>
            <div className="contact-image">
              <img src={myContact} alt={"contact"} />
            </div>
          </Grid>
          <Grid item md={8} xs={12}>
            <div className="contact-info">
              <p className="intro">
                Connecting with people has never been easier before. But I
                believe, everyone should keep himself connected to others. I
                like to stay connected, even if you just stop by to say hello. I
                can be found here:
              </p>
              <Grid container>
                <Grid item md={6} xs={12}>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/lmfyb"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>
                          <Facebook fontSize={"large"} />
                        </p>
                        <p>facebook/lmfyb </p>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/arnabdhar"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>
                          <LinkedIn fontSize={"large"} />
                        </p>
                        <p>linkedin/arnabdhar </p>
                      </a>
                    </li>
                  </ul>
                </Grid>
                <Grid item md={6} xs={12}>
                  <ul>
                    <li>
                      <a
                        href="mailto:arnabshuvo430@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>
                          <Mail fontSize={"large"} />
                        </p>
                        <p>arnabshuvo430@gmail.com</p>
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:+8801681461616"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>
                          <Phone fontSize={"large"} />
                        </p>
                        <p>+44-0755-1866719</p>
                      </a>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </ContactWrapper>
  );
};

export default Contact;
