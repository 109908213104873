import styled, { keyframes } from "styled-components";
import Grid from "@material-ui/core/Grid";

export const TerminalWrapper = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1111;
`;
export const TerminalContainer = styled(Grid)`
  height: 100%;
  .terminal {
    &-box {
      border: 1px solid #f5d71b;
      background: rgba(0, 0, 0, 0.8);
      color: red;
      border-radius: 5px;
    }
    &-header {
      background: #f5d71b;
      color: #000;
      text-align: center;
      padding: 10px;
      font-weight: bold;
    }
    &-body {
      padding: 10px;
      min-height: 400px;
      .content {
        float: left;
        width: 28%;

        p {
          color: #fff;
          margin: 0;
          .pc-name {
            background: #313131;
            padding: 0px 10px;
          }
          .path-name {
            padding: 0px 10px;
            background: #777676;
          }
        }
        @media screen and (max-width: 1919px) {
          width: 38%;
        }
      }
      .dest {
        width: 72%;
        float: left;
        display: inline-block;
        text-align: left;
        @media screen and (max-width: 1919px) {
          width: 62%;
        }
      }
    }
  }
`;

const Typinganimation = keyframes`
	0% { width: 0 }
	10%{width: 10%}
	20%{width: 20%}
	30%{width: 30%}
	40%{width: 40%}
	50%{width: 50%}
	60%{width: 60%}
	70%{width: 70%}
	80%{width: 80%}
	90%{width: 90%}
  	to { width: 100% }`;

export const Typer = styled.p`
  line-height: 15px;
  color: #f5d71b;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0;
  letter-spacing: 0.15em; /* Adjust as needed */
  height: 20px;
  padding-top: 2px;
  animation: ${Typinganimation} 4s steps(30, end);
`;
