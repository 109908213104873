import React from 'react';
import { LayoutWrapper, ChildWrapper } from './styled';
import Taskbar from './Taskbar';

const Layout = (props) => {
	return (
		<LayoutWrapper>
			<ChildWrapper>{props.children}</ChildWrapper>
			<Taskbar />
		</LayoutWrapper>
	);
};

export default Layout;
