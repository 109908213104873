import styled, { keyframes } from 'styled-components';
import Grid from '@material-ui/core/Grid';
import l from '../assets/images/l.jpg';

export const LandWrapper = styled(Grid)`
	background-color: rgb(2, 0, 36);
	background-color: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(12, 13, 13, 1) 100%);
	background-image: url(${l});
	background-repeat: repeat;
	background-position: center center;
	height: 100vh;
	width: 100vw;
	.land-content {
		width: '40%';
		@media screen and (max-width: 767px) {
			width: '80%';
		}
	}
`;
const Typinganimation = keyframes`
	0% { width: 0 }
	10%{width: 10%}
	20%{width: 20%}
	30%{width: 30%}
	40%{width: 40%}
	50%{width: 50%}
	60%{width: 60%}
	70%{width: 70%}
	80%{width: 80%}
	90%{width: 90%}
  	to { width: 100% }`;

export const Typer = styled.p`
	word-wrap: break-word;
	color: #f5d71b;
	text-align: center;
	font-size: 30px;
	font-family: monospace;
	overflow: hidden; /* Ensures the content is not revealed until the animation */
	white-space: nowrap; /* Keeps the content on a single line */
	margin: 0 auto; /* Gives that scrolling effect as the typing happens */
	letter-spacing: 0.15em; /* Adjust as needed */
	animation: ${Typinganimation} 2.5s steps(30, end);
	@media screen and (max-width: 767px) {
		font-size: 19px;
	}
`;
