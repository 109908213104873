import styled from 'styled-components';
import Bg from '../assets/images/mybg.jpg';

export const LayoutWrapper = styled.div`
	background-color: rgb(2, 0, 36);
	background-color: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 35%, rgba(12, 13, 13, 1) 100%);
	background-image: url(${Bg});
	background-size: cover;
	height: 100vh;
	width: 100vw;
	position: realtive;
	overflow-x: hidden;
	overflow-y: scroll;
`;

export const ChildWrapper = styled.div`
	margin-left: 60px;
	width: calc(100% - 60px);
	@media screen and (max-width: 767px) {
		margin-left: 0;
		width: 100%;
		padding: 0 15px;
		padding-bottom: 100px;
	}
`;
