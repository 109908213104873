import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  AboutContainer,
  Aboutme,
  Skill,
  SkillBar,
  AboutWrapper,
} from "./styled";
import Ab from "../../assets/images/ab.jpg";
import ReactGA from "react-ga";

export const About = () => {
  ReactGA.initialize("UA-191423910-1");
  ReactGA.pageview("/home");
  return (
    <AboutContainer container justify="center">
      <AboutWrapper item container md={12}>
        <Grid item md={5}>
          <Aboutme>
            <img className="my-img" src={Ab} alt={"arnab-shuvo"} />
          </Aboutme>
        </Grid>
        <Grid item md={6}>
          <Aboutme>
            <p className="about-title">Hi, My name is Arnab Dhar</p>
            <p className="about-description">
              Innovative Front-end Developer with 5 years experience in blending
              the art of design with skill of object oriented programming to
              deliver an immersive and engaging user experience through
              efficient web applications with modern technologies like ReactJs,
              NodeJs etc.
            </p>

            <p className="about-title">Skill Set</p>
            <Grid container justify="center" alignItems="center">
              <Grid item md={6} xs={12}>
                <Skill>
                  <span className="skill-title">Javascript</span>
                  <SkillBar number={90}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Typescript</span>
                  <SkillBar number={85}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">ReactJs</span>
                  <SkillBar number={95}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Redux</span>
                  <SkillBar number={90}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">NodeJs</span>
                  <SkillBar number={75}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">VueJs</span>
                  <SkillBar number={60}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">AWS</span>
                  <SkillBar number={80}></SkillBar>
                </Skill>
              </Grid>
              <Grid item md={6} xs={12}>
                <Skill>
                  <span className="skill-title">Thunk</span>
                  <SkillBar number={80}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Hook</span>
                  <SkillBar number={80}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">AWS Lambda</span>
                  <SkillBar number={60}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Git</span>
                  <SkillBar number={90}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Adobe Photoshop</span>
                  <SkillBar number={70}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Adobe Illustrator</span>
                  <SkillBar number={75}></SkillBar>
                </Skill>
                <Skill>
                  <span className="skill-title">Cloud Formation</span>
                  <SkillBar number={80}></SkillBar>
                </Skill>
              </Grid>
            </Grid>
          </Aboutme>
        </Grid>
        <Grid item md={11}>
          <Aboutme>
            <div className="experience">
              <p className="about-title">Experience</p>
              <div className="timeline">
                <ul>
                  <li>
                    <span className="company-name">Accenture</span>{" "}
                    <span>(May,2021 - Present)</span>
                    <div className="content">
                      <h3>
                        <span className="company-name">Consultant</span>
                      </h3>
                      <p>
                        Aong with my skill in Javascript, I have got to
                        implement my knowledge of solution architecture using
                        AWS. A playground for extensive work in architecting and
                        building complex applications including Fintech,
                        Insuretech etc.
                      </p>
                      <h5>Stacks:</h5>
                      <h6>
                        <span>ReactJs</span>
                        <span>Redux</span>
                        <span>Thunk</span>
                        <span>NodeJs</span>
                        <span>AWS</span>
                        <span>Lambda</span>
                        <span>Workflow</span>
                        <span>AWS CDK</span>
                        <span>Nest JS</span>
                        <span>Postgres</span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span className="company-name">Brainstation23 Limited</span>{" "}
                    <span>(March,2020 - April 2021)</span>
                    <div className="content">
                      <h3>
                        <span className="company-name">Technical Lead</span>
                      </h3>
                      <p>
                        Working as a Javascript Developer focusing on web
                        development using Reactjs. Also responsible for building
                        API services using Nodejs and cross platform mobile
                        application using React Native.
                      </p>
                      <h5>Stacks:</h5>
                      <h6>
                        <span>ReactJs</span>
                        <span>Redux</span>
                        <span>Thunk</span>
                        <span>NodeJs</span>
                        <span>Material UI</span>
                        <span>Hooks</span>
                        <span>Jest</span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span className="company-name">Portonics Limited</span>{" "}
                    <span>(May,2019 - February 2020)</span>
                    <div className="content">
                      <h3>
                        <span className="company-name">Software Engineer</span>
                      </h3>
                      <p>
                        Worked as full stack developer building web application
                        using ReactJs and Developing API and back office using
                        Laravel PHP framework.
                      </p>
                      <h5>Stacks:</h5>
                      <h6>
                        <span>ReactJs</span>
                        <span>Redux</span>
                        <span>Thunk</span>
                        <span>NodeJs</span>
                        <span>Material UI</span>
                        <span>Hooks</span>
                        <span>PHP</span>
                        <span>Laravel</span>
                        <span>MySql</span>
                        <span>AWS Lambda</span>
                        <span>Jenkins</span>
                      </h6>
                    </div>
                  </li>
                  <li>
                    <span className="company-name">
                      Workpsace Infotech Limited
                    </span>{" "}
                    <span>(January,2016 - April 2019)</span>
                    <div className="content">
                      <h3>
                        <span className="company-name">Software Engineer</span>
                      </h3>
                      <p>
                        Worked as full stack developer building web application
                        using ReactJs and Developing API and back office using
                        different PHP frameworks such as Laravel, SlimPhp
                      </p>
                      <h5>Stacks:</h5>
                      <h6>
                        <span>ReactJs</span>
                        <span>Redux</span>
                        <span>Thunk</span>
                        <span>PHP</span>
                        <span>Laravel</span>
                        <span>SlimPhp</span>
                        <span>D3Js</span>
                        <span>Ansible</span>
                        <span>Phinx</span>
                      </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Aboutme>
        </Grid>
        <Grid item md={11}>
          <Aboutme>
            <p className="about-title">Facts About Me</p>
            <div className="about-description">
              <ul>
                <li>I am a citizen of Bangladesh</li>
                <li>
                  Aside from programming, I like{" "}
                  <span className="bolder">Photography</span>
                </li>
                <li>
                  <span className="bolder">Violin</span> is something, that
                  always attracts me the most
                </li>
                <li>
                  I have been called <span className="bolder">Versatile</span>{" "}
                  as I always look forward to learning and implementing new
                  things
                </li>
                <li>
                  My love for <span className="bolder">Burger</span> will never
                  let me be a Vegetarien{" "}
                </li>
              </ul>
            </div>
          </Aboutme>
        </Grid>
      </AboutWrapper>
    </AboutContainer>
  );
};

export default About;
