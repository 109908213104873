import React from "react";
import { PageContent, PageContainer, HomePageContent } from "./styled";
import Grid from "@material-ui/core/Grid";
import { LinkedIn, Facebook, GitHub, GetApp } from "@material-ui/icons";
import cv from "../../assets/files/CV_Arnab_Dhar.pdf";
import Tooltip from "@material-ui/core/Tooltip";
import ReactGA from "react-ga";

const Home = () => {
  ReactGA.initialize("UA-191423910-1");
  ReactGA.pageview("/home");
  return (
    <PageContent>
      <PageContainer container alignItems="center" justify={"center"}>
        <Grid item md={4}>
          <HomePageContent>
            <p className="greeting">Hello</p>
            <p className="name">
              I'm{" "}
              <span className="initial">
                A<span className="secondary">rnab</span>{" "}
              </span>{" "}
              <span className="initial">
                D<span className="secondary">har</span>
              </span>
            </p>
            <p className="post">Javascript Developer</p>

            <ul>
              <li>
                <a
                  href="https://www.facebook.com/lmfyb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title="Facebook">
                    <Facebook fontSize={"large"} />
                  </Tooltip>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/arnabdhar"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title="LinkedIn">
                    <LinkedIn fontSize={"large"} />
                  </Tooltip>
                </a>
              </li>
              <li>
                <a
                  href="https://www.github.com/arnab-shuvo"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip title="Github">
                    <GitHub fontSize={"large"} />
                  </Tooltip>
                </a>
              </li>
              <li>
                <a href={cv} download={"CV_ARNAB_DHAR.pdf"}>
                  <Tooltip title="Download My CV">
                    <GetApp fontSize={"large"} />
                  </Tooltip>
                </a>
              </li>
            </ul>
          </HomePageContent>
        </Grid>
      </PageContainer>
    </PageContent>
  );
};

export default Home;
