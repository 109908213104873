import styled from 'styled-components';

export const TaskbarWrapper = styled.div`
	position: absolute;
	background: rgba(71, 81, 179, 0.09);
	top: 0;
	left: 0;
	width: 60px;
	height: 100vh;
	@media screen and (max-width: 767px) {
		bottom: 0;
		top: unset;
		left: 0;
		width: 100%;
		height: 75px;
		background: rgba(13, 14, 35, 1);
		z-index: 111111;
	}
`;
