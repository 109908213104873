import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const PageContent = styled.div`
	height: 100vh;
`;

export const PageContainer = styled(Grid)`
	min-height: 100vh;
	@media screen and (max-width: 767px) {
		padding: 15px;
	}
`;

export const HomePageContent = styled.div`
	p {
		margin: 0;
		text-shadow: 8px 6px 6px #000000;

		&.greeting {
			font-size: 35px;
			margin-bottom: 15px !important;
			@media screen and (max-width: 767px) {
				font-size: 30px;
			}
		}
		&.name {
			font-size: 70px;
			color: #f5d71b;
			font-weight: bold;
			margin-bottom: 10px;
			margin-bottom: 0px;
			@media screen and (max-width: 767px) {
				font-size: 38px;
			}
		}
		&.post {
			font-size: 40px;
			margin-bottom: 20px;
			@media screen and (max-width: 767px) {
				font-size: 28px;
			}
		}
	}
	ul {
		padding: 0;
		margin: 0;
		li {
			list-style: none;
			margin-right: 25px;
			display: inline-block;
			text-shadow: 8px 6px 6px #000000;

			a {
				color: #fff !important;
				&:hover {
					color: #f5d71b !important;
				}
			}
		}
	}
`;

export const MyImage = styled.div`
	background-color: #ddc;
	border: solid 5vmin #eee;
	border-bottom-color: #fff;
	border-left-color: #eee;
	border-radius: 2px;
	border-right-color: #eee;
	border-top-color: #ddd;
	box-shadow: 6px 11px 9px 1px #000;
	box-sizing: border-box;
	display: inline-block;
	width: 100%;
	padding: 20px;
	position: relative;
	text-align: center;
	&:before {
		border-radius: 2px;
		bottom: -2vmin;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25) inset;
		content: '';
		left: -2vmin;
		position: absolute;
		right: -2vmin;
		top: -2vmin;
	}
	&:after {
		border-radius: 2px;
		bottom: -2.5vmin;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
		content: '';
		left: -2.5vmin;
		position: absolute;
		right: -2.5vmin;
		top: -2.5vmin;
	}
	img {
		width: 100%;
	}
`;
