import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const PortfolioContainer = styled.div`
	min-height: 100vh;
	.my-img {
		width: 100%;
	}
	position: relative;
`;

export const PortfolioDetails = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(13, 14, 37, 0.95);
	z-index: 1111;
	top: 0;
	left: 0;
`;
export const PortfolioWrapper = styled(Grid)`
	padding: 30px;
	background: rgba(13, 14, 37);
	min-height: 100vh;
	position: relative;
	.project-wrapper {
		position: relative;
		overflow: hidden;
		height: 285px;
		text-align: center;
		box-sizing: border-box;
		padding: 10px;
		background-image: url(${(props) => props.image});
		background-size: cover;
		background-position: center;
		box-shadow: 0px 0px 3px 0px #0014ff;

		img {
			height: 100%;
		}
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			background: rgb(0 0 0 / 0%);
			z-index: 111;
			top: 0;
			left: 0;
			padding: 30px;
			box-sizing: border-box;
			cursor: pointer;
			&:hover {
				background: rgb(0 0 0 / 80%);
			}
			&-content {
				transform: scale(0);
				border: 1px solid yellow;
				position: relative;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				transition: all 0.5s ease;
				p {
					text-align: center;
					font-size: 25px;
					position: relative;
					padding-bottom: 10px;
					&:after {
						content: '';
						position: absolute;
						height: 5px;
						width: 70%;
						left: 0;
						right: 0;
						bottom: 0;
						margin: 0 auto;
						background: yellow;
					}
					@media screen and (max-width: 767px) {
						font-size: 18px;
					}
				}
			}
			&:hover {
				.overlay-content {
					transform: scale(1);
				}
			}
			@media screen and (max-width: 767px) {
				background: rgb(0 0 0 / 80%);
				.overlay-content {
					transform: scale(1);
				}
			}
		}
	}
`;
