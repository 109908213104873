import React, { useState, useEffect } from "react";
import { LandWrapper, Typer } from "./styled";
import PageView from "./page-view";

const Landing = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      if (!loaded) {
        setLoaded(true);
      }
    }, 3000);
  });
  return (
    <div id={"os"}>
      {!loaded && (
        <LandWrapper container alignItems={"center"} justify={"center"}>
          <div>
            <Typer>Welcome, I am loading...</Typer>
          </div>
        </LandWrapper>
      )}
      {loaded && <PageView />}
    </div>
  );
};

export default Landing;
