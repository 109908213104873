import React from 'react';
import Home from './Home';
import About from './About';
import Portfolio from './Portfolio';
import Contact from './Contact';
import Layout from '../Layout';
import { Route, Switch, Redirect } from 'react-router-dom';

const PageView = () => {
	return (
		<Layout>
			<Switch>
				<Route exact path={'/'} component={Home} />
				<Route exact path={'/about-me'} component={About} />
				<Route exact path={'/portfolio'} component={Portfolio} />
				<Route exact path={'/contact'} component={Contact} />
				<Route exact path={'/*'} render={() => <Redirect to='/' />} />
			</Switch>
		</Layout>
	);
};
export default PageView;
